import { useEffect } from "react";
import "./Sec1.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faPercent,
  faMoneyCheckDollar,
  faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const Navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="Sec1_main_div">
      <div className="Sec1_sub_div">
        <div className="heading_text_main">
          <h5>Elevate Your Efficiency, Outsource With Ingenuity!</h5>
          <h1 className="web_text">
            Outsourced{" "}
            <span>
              Accounting <br /> Services
            </span>{" "}
            For CPA Firms
          </h1>
          <h1 className="mobile_text">
            Outsourced <span>Accounting Services</span> For CPA Firms
          </h1>
          <p>
            Optimize your business efficiency with top-notch outsourced
            accounting services. Our expert team ensures accurate financial
            management for sustainable growth. Discover tailored services today!
          </p>
          <div className="button_of_div">
            <button className="btn1" onClick={() => Navigate("/ContactUs")}>
              {" "}
              Get Started
            </button>
            <button className="btn2" onClick={() => Navigate("/ContactUs")}>
              Free Consultantion
            </button>
          </div>
        </div>
        <div className="Sec1_bottom_div">
          <div className="main_card_div">
            <div className="card_by_id" data-aos="fade-up" data-aos-delay="300">
              <FontAwesomeIcon className="icon" icon={faBook} />
              <h1>Bookkeeping</h1>
            </div>
            <div className="card_by_id" data-aos="fade-up" data-aos-delay="400">
              <FontAwesomeIcon className="icon" icon={faChartColumn} />
              <h1>Finance & Accounting</h1>
            </div>
            <div className="card_by_id" data-aos="fade-up" data-aos-delay="500">
              <FontAwesomeIcon className="icon" icon={faMoneyCheckDollar} />
              <h1>Payroll Processing</h1>
            </div>
            <div className="card_by_id" data-aos="fade-up" data-aos-delay="600">
              <FontAwesomeIcon className="icon" icon={faPercent} />
              <h1>Payroll Processing</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
