import Logo1 from "../../../../assets/Brand_logo/Amazon.png";
import Logo2 from "../../../../assets/Brand_logo/Apple.png";
import Logo3 from "../../../../assets/Brand_logo/Google.png";
import Logo4 from "../../../../assets/Brand_logo/Instagram.png";
import Logo5 from "../../../../assets/Brand_logo/intuit-proconnect-file.png";
import Logo6 from "../../../../assets/Brand_logo/Mercedes-Benz.png";
import Logo7 from "../../../../assets/Brand_logo/Meta.png";
import Logo8 from "../../../../assets/Brand_logo/Microsoft.png";
import Logo9 from "../../../../assets/Brand_logo/Mitsubishi.png";
import Logo10 from "../../../../assets/Brand_logo/Nike.png";
import Logo11 from "../../../../assets/Brand_logo/Porsche.png";
import Logo12 from "../../../../assets/Brand_logo/Samsung.png";
import Logo13 from "../../../../assets/Brand_logo/Toyota.png";
import Logo14 from "../../../../assets/Brand_logo/Walmart.png";

const Sec7data = () => [
  { id: 1, img: Logo1 },
  { id: 2, img: Logo2 },
  { id: 3, img: Logo3 },
  { id: 4, img: Logo4 },
  { id: 5, img: Logo5 },
  { id: 6, img: Logo6 },
  { id: 7, img: Logo7 },
  { id: 8, img: Logo8 },
  { id: 9, img: Logo9 },
  { id: 10, img: Logo10 },
  { id: 11, img: Logo11 },
  { id: 12, img: Logo12 },
  { id: 13, img: Logo13 },
  { id: 14, img: Logo14 },
];

export default Sec7data;
