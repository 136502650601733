import React from "react";
import "./AU_sec3.css";
import SVG1 from "../../../assets/Svg/AU_sec3_1.svg";
import SVG2 from "../../../assets/Svg/AU_sec3_2.svg";
import SVG3 from "../../../assets/Svg/AU_sec3_3.svg";
import SVG4 from "../../../assets/Svg/AU_sec3_4.svg";

const Index = () => {
  const sec3AuData = [
    {
      id: 1,
      icon: SVG1,
      headingtext: "Walk an Extra Mile",
      desciption:
        " At Wealthlyte, we are committed to exceeding expectations for each client and employee. We recognize that our success is intertwined with theirs, and this shared commitment has contributed significantly to our growth. ",
    },
    {
      id: 2,
      icon: SVG2,
      headingtext: "TAT is Everything",
      desciption:
        " Our top priority is the turnaround time. All our efforts, whether introducing new processes or adopting technology, are aimed at ensuring timely delivery. ",
    },
    {
      id: 3,
      icon: SVG3,
      headingtext: "Fast Flexible Responsive",
      desciption:
        " In this era, prioritizing agility, flexibility, and nimbleness is crucial due to intense competition. We align with our clients’ pace through promptness, adaptability, and responsiveness. ",
    },
    {
      id: 4,
      icon: SVG4,
      headingtext: "Integrity & Transparency",
      desciption:
        " Integrity is ingrained in our core beliefs. We prioritize honesty, consistency, and unwavering commitment to our robust moral and ethical principles. Our actions consistently reflect transparency, staying true to our fundamental values. ",
    },
  ];
  return (
    <div className="main_Sec3_of_AU">
      <div className="sub_main_div_of_sec3_AU">
        <div className="upper_heading_text">
          <h1>Providing Best Business Solution For Growing CPA & EA Firms</h1>
          <button
            className="btn2"
            style={{
              boxShadow:
                "var(--seconsry_button-bg-color) 0px 5px 15px; !important",
            }}>
            Learn More
          </button>
        </div>
        <div className="content_div_sec3_AU">
          {sec3AuData.map((item, index) => {
            return (
              <div className="main_sec3_of_AU_card" key={index}>
                <img src={item.icon} alt="" />
                <h3>{item.headingtext}</h3>
                <p>{item.desciption}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;
