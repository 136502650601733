import React from "react";
import "./Au_sec2.css";
import Sec2_AU from "../../../assets/Nabil.png";
import owner1 from "../../../assets/Aboutus/owner_1.JPG";
import owner2 from "../../../assets/Aboutus/Rehan.JPG";

const Index = () => {
  const linkdein_icon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_493_104)">
        <rect width="40" height="40" rx="5" fill="white" />
        <path
          d="M34.0833 34.0833H28.1567V24.8C28.1567 22.5866 28.1167 19.7366 25.0733 19.7366C21.9867 19.7366 21.5133 22.15 21.5133 24.6366V34.0833H15.5867V14.9933H21.2767V17.6033H21.3567C22.5167 15.6233 24.6767 14.4367 26.97 14.52C32.9767 14.52 34.0867 18.47 34.0867 23.6133L34.0833 34.0799V34.0833ZM8.9 12.3833C7.98958 12.379 7.11765 12.0156 6.47356 11.3721C5.82948 10.7287 5.46526 9.8571 5.46 8.94668C5.46 7.06002 7.01333 5.5067 8.9 5.5067C10.7867 5.5067 12.3367 7.06002 12.34 8.94668C12.34 10.8333 10.7867 12.3833 8.9 12.3833ZM11.8633 34.0833H5.93V14.9933H11.8633V34.0833ZM37.0333 4.95439e-05H2.95C2.17717 -0.0044392 1.43379 0.296247 0.88141 0.836767C0.32903 1.37729 0.0122822 2.11396 0 2.88671V37.1132C0.0122822 37.886 0.32903 38.6227 0.88141 39.1632C1.43379 39.7037 2.17717 40.0044 2.95 39.9999H37.0367C37.8113 40.0062 38.5571 39.7065 39.112 39.166C39.6668 38.6254 39.986 37.8878 40 37.1132V2.88337C39.986 2.10947 39.6666 1.37247 39.1115 0.833009C38.5565 0.293544 37.8107 -0.00466083 37.0367 0.00338262L37.0333 4.95439e-05Z"
          fill="#0A66C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_493_104">
          <rect width="40" height="40" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <>
      <div className="main_sec2_of_AU">
        <div className="sub_main_Sec2_AU">
          <div className="image_div_of_Sec2_AU">
            <div className="main_imagediv">
              <img src={owner1} alt="" />
              <div className="div_of_icon">
                <a
                  href="https://www.linkedin.com/in/caismaillakhani?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank">
                  {linkdein_icon}
                </a>
              </div>
            </div>
          </div>
          <div className="text_div_of_Sec2_AU">
            <div className="top_of_sec2_Au">
              <div className="first_text_of_sec2AU">
                <p>
                  With over 8 years of comprehensive experience in bookkeeping,
                  payroll, taxation, GST, and audit, Ismail Lakhani is a
                  distinguished Chartered Accountant committed to providing
                  exceptional financial services. As the founder of I I Lakhani
                  and Co., Ismail has established a reputable firm known for its
                  meticulous attention to detail and dedication to client
                  satisfaction.
                </p>
                <p>
                  In his pursuit to expand and innovate in the field of
                  financial services, Ismail founded Wealthlyte Consultancy
                  Private Limited in November 2023. This new venture focuses on
                  outsourcing financial functions, allowing businesses to
                  streamline their operations and focus on their core
                  competencies. Wealthlyte Consultancy offers a range of
                  services designed to enhance efficiency, ensure compliance,
                  and provide strategic financial insights.
                </p>
              </div>
              <div className="second_text_of_Sec2_au">
                <h3>Areas of Expertise:</h3>
                <p>
                  Bookkeeping, Payroll, Sales Tax Preparation, Tax Preparation,
                  Remote Staffing, Finance/Budgeting.
                </p>
              </div>
              <div className="second_text_of_Sec2_au">
                <h3>About I I Lakhani and Co.:</h3>
                <p>
                  Founded by Ismail Lakhani, I I Lakhani and Co. has earned a
                  reputation for its excellence in delivering tailored financial
                  solutions to a diverse client base. The firm prides itself on
                  its ethical approach, expert knowledge, and unwavering
                  commitment to client success
                </p>
              </div>
              <div className="second_text_of_Sec2_au">
                <h3>About Wealthlyte Consultancy Private Limited:</h3>
                <p>
                  Wealthlyte Consultancy is Ismail’s venture of 2023, created to
                  address the growing need for outsourced financial services. By
                  leveraging his extensive experience and expertise, Ismail aims
                  to provide businesses with cost-effective and efficient
                  financial management solutions, ensuring they stay competitive
                  in today’s dynamic market
                </p>
                <p>
                  Ismail Lakhani is dedicated to empowering businesses with the
                  financial tools and strategies they need to thrive. His
                  forward-thinking approach and commitment to excellence make
                  him a trusted partner for businesses seeking expert financial
                  guidance.
                </p>
              </div>
            </div>
            <div className="name_and_sign_div_sec2_AU">
              <div className="Name_of_funder_div">
                <h1>CA Ismail Lakhani</h1>
                <p>Co-Founder</p>
              </div>
              {/* <div className="Sign_of_funder_div">
                <h1>Lorem ipsum.</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="main_sec2_of_AU">
        <div className="sub_main_Sec2_AU_two">
          <div className="text_div_of_Sec2_AU">
            <div className="top_of_sec2_Au">
              <div className="first_text_of_sec2AU">
                <h1>Expert Financial Services for Businesses and CPAs</h1>
                <p>
                  Started journey in 2016 by establishing Reehan Memon &
                  Associates, a Chartered Accountancy Firm, committed to provide
                  guaranteed and satisfying services to Entrepreneurs and
                  Business Entities in Business Registrations, Bookkeeping,
                  Taxation, Finance, and Regulatory Compliances.
                </p>
              </div>
              <div className="second_text_of_Sec2_au">
                <p>
                  With an experienced team of accountants and professionals
                  strive to provide seamless and quality services to our clients
                </p>
                <p>
                  Additionally, in 2023 established Wealthlyte with a vision to
                  provide best-in-class Bookkeeping, Payroll, Accounts Payable
                  and Receivable Management, Reconciliation, and more, using a
                  SOP-driven approach to Business Entities, CPA and EA.
                </p>
              </div>
            </div>
            <div className="name_and_sign_div_sec2_AU">
              <div className="Name_of_funder_div">
                <h1>Reehan Memon</h1>
                <p>Co-Founder</p>
              </div>
              {/* <div className="Sign_of_funder_div">
                <h1>Lorem ipsum.</h1>
              </div> */}
            </div>
          </div>
          <div className="image_div_of_Sec2_AU">
            <div className="main_imagediv2">
              <img src={owner2} alt="" />
              <div className="div_of_icon">
                <a
                  href="https://www.linkedin.com/in/ca-rehan-memon-44426566?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank">
                  {linkdein_icon}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
