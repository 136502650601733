import { useEffect } from "react";
import "./Sec3.css";
import sec3img from "../../../../assets/Sec3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faMoneyBill1,
  faBullseye,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";

const Index = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="Sec3_main_div">
      <div className="Sec3_sub_div">
        <div className="sec3_main_text_div">
          <h1 data-aos="fade-right">Why Company Name?</h1>
          <div className="inner_text_div" data-aos="fade-right">
            <span>
              <FontAwesomeIcon icon={faGraduationCap} />
            </span>
            <div className="text_content_div">
              <h3>Expertise and Experience</h3>
              <p>
                Our team brings a wealth of expertise and experience in the
                field of accounting. With a proven track record of delivering
                accurate and timely financial services
              </p>
            </div>
          </div>
          <div className="inner_text_div" data-aos="fade-right">
            <span>
              <FontAwesomeIcon icon={faMoneyBill1} />
            </span>
            <div className="text_content_div">
              <h3>Cost-Effectiveness</h3>
              <p>
                You can reduce overhead costs associated with hiring, training,
                and retaining specialized staff while still enjoying
                high-quality financial services.
              </p>
            </div>
          </div>
          <div className="inner_text_div" data-aos="fade-right">
            <span>
              <FontAwesomeIcon icon={faBullseye} />
            </span>
            <div className="text_content_div">
              <h3>Focus on Core Competencies</h3>
              <p>
                CPA Firms can concentrate on its core competencies and strategic
                objectives. This allows your internal team to focus on
                activities that directly contribute to business growth and
                profitability.
              </p>
            </div>
          </div>
          <div className="inner_text_div" data-aos="fade-right">
            <span>
              <FontAwesomeIcon icon={faDatabase} />
            </span>
            <div className="text_content_div">
              <h3>Data Security and Confidentiality</h3>
              <p>
                We prioritize the security and confidentiality of your financial
                data. Our robust data protection measures, including encryption
                and secure protocols, ensure that your sensitive information is
                handled with the utmost care.
              </p>
            </div>
          </div>
        </div>
        <div className="sec3_main_img_div">
          <div
            className="images_div"
            data-aos="flip-right"
            data-aos-delay="1000">
            <img src={sec3img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
